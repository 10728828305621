.about{
    position: relative;
    padding-top: 115px;
}

.about-header{
    position: relative;
    img{
        height: 30vh;
        width: 100%;
    }
    h1{
        text-align: center;
        font-family: 'Raleway', sans-serif;
        color: black;
    }
}

.about-header_text{
    text-transform: capitalize;
    width: 100%;
    height: 30vh;
    position: absolute;
    padding: 80px 0;
    top: 0%;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.about-first{
    min-height: 50vh;
    background-color: #f1f1f1;
    padding: 120px 200px;

    h1{
        text-transform: uppercase;
        font-size: 40px;
        font-family: 'Raleway', sans-serif;
    }
    h3{
        font-family: 'Maven Pro', sans-serif;
        padding-top: 20px;
    }
    p{
        padding: 10px 0;
        font-family: 'Maven Pro', sans-serif;
    }
    @media screen and (max-width: 1000px) {
        padding: 70px 60px;
        margin: 0;
      }
      @media screen and (min-width: 1100px) and (max-width: 1300px) {
        padding: 100px 100px;
        margin: 0;
      }
}

.about-second{
    min-height: 100vh;
    padding: 80px 200px 120px;
    background-color: #000;

    h1{
        text-transform: uppercase;
        font-size: 30px;
        font-family: 'Raleway', sans-serif;
        font-weight: bolder;
        padding: 40px 0 20px;
        color: #fff;
    }
    h4{
        font-family: 'Maven Pro', sans-serif;
        padding: 10px 0;
        color: #fff;
    }
    p{
        font-family: 'Maven Pro', sans-serif;
        color: #a6a6a6;
    }
    li{
        margin-bottom: 15px;
        font-family: 'Maven Pro', sans-serif;
        color: #a6a6a6;
    }
    @media screen and (max-width: 1000px) {
        padding: 70px 60px;
        margin: 0;
      }
      @media screen and (min-width: 1100px) and (max-width: 1300px) {
        padding: 100px 100px;
        margin: 0;
      }
}