.employment{
    position: relative;
    padding-top: 115px;
}

.employment-header{
    position: relative;
    img{
        height: 30vh;
        width: 100%;
    }
    h1{
        text-align: center;
        font-family: 'Raleway', sans-serif;
        color: black;
    }
    h3{
        text-align: center;
        font-family: 'Maven Pro', sans-serif;
        color: black;
        padding-top: 20px;
    }
}

.employment-header_text{
    text-transform: capitalize;
    width: 100%;
    height: 30vh;
    position: absolute;
    padding: 60px 0;
    top: 0%;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.employment-roles{
    min-height: 50vh;
    background-color: #E1EFF2;
    padding: 0;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    

    // @media screen and (max-width: 1000px) {
    //     padding: 70px 50px;
    //     justify-content: center;
    //     align-items: center;
    //   }
}

.role-text{
    text-align: center;
    width: 100%;
    height: 20vh;
    position: absolute;
    padding: 50px 0;
    top: 40%;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    // @media screen and (max-width: 1000px) {
    //     width: 100%;
    //     top: 64%;
    //     height: 12vh;
    //     padding: 15px 0;
        
    //   }
}

.role-1{
    
    position: relative;
    img{
        width: 30rem;
        height: 18rem;
    }
    h1{
        text-align: center;
        font-family: 'Raleway', sans-serif;
        color: black;
        font-size: large;
        font-weight: bolder;
        text-transform: uppercase;
    }
    h2{
        text-transform: capitalize;
        text-align: center;
        font-family: 'Maven Pro', sans-serif;
        color: black;
        padding-top: 20px;
        font-size: medium;
    }
    @media screen and (max-width: 1000px) {
        img{
            max-width: 22rem;
            height: 20rem;
            padding-top: 20px;
        }
      }
      
}
.role-2{
    position: relative;
    img{
        width: 30rem;
        height: 18rem;
    }
    h1{
        text-align: center;
        font-family: 'Raleway', sans-serif;
        color: black;
        font-size: large;
        font-weight: bolder;
        text-transform: uppercase;
    }
    h2{
        text-transform: capitalize;
        text-align: center;
        font-family: 'Maven Pro', sans-serif;
        color: black;
        padding-top: 20px;
        font-size: medium;
    }
    @media screen and (max-width: 1000px) {
        img{
            max-width: 22rem;
            height: 20rem;
            padding-top: 20px;
        }
      }
}
.role-3{
    position: relative;
    img{
        width: 30rem;
        height: 18rem;
    }
    h1{
        text-align: center;
        font-family: 'Raleway', sans-serif;
        color: black;
        font-size: large;
        font-weight: bolder;
        text-transform: uppercase;
    }
    h2{
        text-transform: capitalize;
        text-align: center;
        font-family: 'Maven Pro', sans-serif;
        color: black;
        padding-top: 20px;
        font-size: medium;
    }
    @media screen and (max-width: 1000px) {
        img{
            max-width: 22rem;
            height: 20rem;
            padding-top: 20px;
        }
      }
}
.role-4{
    position: relative;
    img{
        width: 30rem;
        height: 18rem;
    }
    h1{
        text-align: center;
        font-family: 'Raleway', sans-serif;
        color: black;
        font-size: large;
        font-weight: bolder;
        text-transform: uppercase;
    }
    h2{
        text-transform: capitalize;
        text-align: center;
        font-family: 'Maven Pro', sans-serif;
        color: black;
        padding-top: 20px;
        font-size: medium;
    }
    @media screen and (max-width: 1000px) {
        img{
            max-width: 22rem;
            height: 20rem;
            padding-top: 20px;
            padding-bottom: 30px;
        }
      }
}
.employment-apply{
    min-height: 90vh;
    background-color: #f1f1f1;
    display: flex;
}

.employment-apply_1{
    flex: 50%;
    padding: 90px 100px 30px;

    @media screen and (max-width: 1000px) {
        padding: 90px 50px;
    }
    
    h1{
        font-family: 'Raleway', sans-serif;
        text-align: center;
    }
    h5{
        text-align: center;
        font-family: 'Maven Pro', sans-serif;
        padding: 20px 0;
    }
    label{
        font-family: 'Maven Pro', sans-serif;
        color: #454545;
    }
    select{
        outline: none;
    }

    .employment-form .form-field {
        position: relative;
        margin: 25px 0;
      }
      .employment-form .input-text {
        display: block;
        width: 100%;
        height: 42px;
        border-width: 0 0 2px 0;
        border-color: #000;
        font-size: small;
        line-height: 20px;
        font-weight: 200;
        font-family: 'Maven Pro', sans-serif;
        background-color: transparent;
      }
      .employment-form .select-text {
        display: block;
        width: 100%;
        height: 42px;
        border-width: 0 0 2px 0;
        border-color: #000;
        font-size: small;
        line-height: 20px;
        font-weight: 200;
        font-family: 'Maven Pro', sans-serif;
      }
      .employment-form .input-date {
        display: block;
        width: 100%;
        height: 42px;
        border-width: 0 0 2px 0;
        border-color: #000;
        font-size: medium;
        line-height: 30px;
        font-weight: 200;
        font-family: 'Maven Pro', sans-serif;
        background-color: transparent;
      }
      .employment-form .input-text:focus {
        outline: none;
      }
      .employment-form .input-text:focus + .label,
      .employment-form .input-text.not-empty + .label {
        -webkit-transform: translateY(-24px);
                transform: translateY(-24px);
      }
      .employment-form .label {
        position: absolute;
        left: 20px;
        bottom: 11px;
        font-size: 16px;
        line-height: 26px;
        font-weight: 200;
        color: #000;
        cursor: text;
        transition: -webkit-transform .2s ease-in-out;
        transition: transform .2s ease-in-out;
        transition: transform .2s ease-in-out, 
        -webkit-transform .2s ease-in-out;
      }
      .employment-form .submit-btn {
        display: inline-block;
        background-color: #000;
        background-image: linear-gradient(125deg,#919600,#3ad59f);
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 16px;
        padding: 8px 16px;
        border: none;
        width:200px;
        cursor: pointer;
      }
}

.employment-apply_2{
    flex: 50%;
    
    img{
        width: 100%;
        height: 100%;
    }
    @media screen and (max-width: 1300px) {
        display: none;
    }
}