.home{
    position: relative;
    padding-top: 115px;
}
.home-board{
    position: relative;
    img{
        display: block;
        height: 90vh;
        width: 100%;
    }
    h1{
        text-align: center;
        font-family: 'Raleway', sans-serif;
        color: black;
    }
    h2{
        text-align: center;
        font-family: 'Maven Pro', sans-serif;
        color: black;
    }
    @media screen and (max-width: 1000px) {
        img{
            height: 40vh;
        }
        h1{
            font-size: large;
        }
        h2{
            font-size: medium;
        }
      }
}

.home-text{
    text-transform: uppercase;
    width: 100%;
    height: 30vh;
    position: absolute;
    top: 67%;
    padding: 60px 0;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);

    @media screen and (max-width: 1000px) {
        height: 20vh;
        padding: 40px 0;
        top: 50%;
      }
}

.home-about{
    min-height: 50vh;
    background-color: #E1EFF2;
    padding: 150px 200px;

    h1{
        text-transform: uppercase;
        font-size: 30px;
        font-family: 'Raleway', sans-serif;
    }
    h3{
        padding: 20px 0;
        font-family: 'Maven Pro', sans-serif;
        font-weight: lighter;
    }
    p{
        font-family: 'Maven Pro', sans-serif;
        justify-content: center;
        text-align: justify;
    }

    @media screen and (max-width: 1000px) {
        padding: 70px 60px;
        margin: 0;
      }
      @media screen and (min-width: 1100px) and (max-width: 1300px) {
        padding: 100px 100px;
        margin: 0;
      }
}

.home-services{
    background-color: #F1F1F1;
    padding: 150px 200px;
    min-height: 50vh;

    h1{
        text-transform: uppercase;
        font-size: 30px;
        font-family: 'Raleway', sans-serif;
    }
    @media screen and (max-width: 1000px) {
        padding: 70px 50px;
      }
    @media screen and (min-width: 1001px) and (max-width: 1300px) {
        padding: 100px 80px;
        
      }
}

.home-services_container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    h2{
        color: #5F9EA0;
        font-size: larger;
    }
    p{
        text-align: justify;
        font-size: 14px;
    }
}

.home-services_content{
    max-width: 270px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
}

.home-contact{
    background-color: #FFF;
    min-height: 70vh;
    padding: 150px;
    display: flex;

    h1{
        font-family: 'Raleway', sans-serif;
        text-transform: uppercase;
        padding-bottom: 40px;
    }
    h3{
        font-family: 'Maven Pro', sans-serif;
        padding: 10px 0;
        font-size: large;
    }

    @media screen and (max-width: 1000px) {
        display: block;
        padding: 70px 50px;
      }
}

.contact-1{
    flex: 50%;
}

.contact-2{
    flex: 50%;
    
    .contact-form .form-field {
        position: relative;
        margin: 32px 0;
      }
      .contact-form .input-text {
        display: block;
        width: 100%;
        height: 42px;
        border-width: 0 0 2px 0;
        border-color: #000;
        font-size: small;
        line-height: 20px;
        font-weight: 200;
        font-family: 'Maven Pro', sans-serif;
        background-color: transparent;;
      }
      .contact-form .input-text:focus {
        outline: none;
      }
      .contact-form .input-text:focus + .label,
      .contact-form .input-text.not-empty + .label {
        -webkit-transform: translateY(-24px);
                transform: translateY(-24px);
      }
      .contact-form .label {
        position: absolute;
        left: 20px;
        bottom: 11px;
        font-size: 16px;
        line-height: 26px;
        font-weight: 200;
        color: #000;
        cursor: text;
        transition: -webkit-transform .2s ease-in-out;
        transition: transform .2s ease-in-out;
        transition: transform .2s ease-in-out, 
        -webkit-transform .2s ease-in-out;
      }
      .contact-form .submit-btn {
        display: inline-block;
        background-color: #000;
        background-image: linear-gradient(125deg,#0700b8,#00ff88);
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 16px;
        padding: 8px 16px;
        border: none;
        width:200px;
        cursor: pointer;
      }

      
      
}


// .home{
//     position: relative;
//     background: url("../../assets/bgIMG.png");
//     background-size: cover;
//     background-position: center;
//     background-repeat: repeat;
  
//     // disable the default padding styles
//     .app__wrapper {
//       padding: 0;
  
//       .copyright {
//         display: none;
//       }
//     }
//   }
  
//   .app__header {
//     flex: 1;
//     width: 100%;
//     height: 100%;
//     flex-direction: row;
  
//     padding: 6rem 2rem 0rem;
  
//     @media screen and (min-width: 2000px) {
//       padding-top: 8rem;
//     }
  
//     @media screen and (max-width: 1200px) {
//       flex-direction: column;
//     }
  
//     @media screen and (max-width: 450px) {
//       padding: 6rem 1rem 2rem;
//     }
//   }
  
//   .app__header-info {
//     flex: 0.65;
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: flex-start;
//     height: 100%;
  
//     margin: 0 2rem;
  
//     @media screen and (max-width: 1200px) {
//       width: 100%;
//       margin-right: 0rem;
//     }
//   }
  
//   .app__header-badge {
//     width: 100%;
//     display: flex;
//     justify-content: flex-end;
//     align-items: flex-end;
//     flex-direction: column;
  
//     .badge-cmp,
//     .tag-cmp {
//       padding: 1rem 2rem;
//       background: var(--white-color);
//       border-radius: 15px;
//       flex-direction: row;
//       width: auto;
  
//       box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
//     }
  
//     .tag-cmp {
//       flex-direction: column;
//       margin-top: 3rem;
  
//       p {
//         width: 100%;
//         text-transform: uppercase;
//         text-align: right;
//       }
//     }
  
//     span {
//       font-size: 2.5rem;
  
//       @media screen and (min-width: 2000px) {
//         font-size: 5rem;
//       }
//     }
  
//     @media screen and (max-width: 1200px) {
//       justify-content: flex-start;
//       align-items: flex-start;
//     }
//   }
  
//   .app__header-circles {
//     flex: 0.75;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-evenly;
//     align-items: flex-start;
//     height: 100%;
//     margin-left: 1rem;
  
//     div {
//       width: 100px;
//       height: 100px;
//       border-radius: 50%;
  
//       background: var(--white-color);
//       box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  
//       img {
//         width: 60%;
//         height: 60%;
//       }
//     }
  
//     // circles with different height & width
  
//     div:nth-child(1) {
//         width: 100px;
//         height: 100px;
//     }
    
//     div:nth-child(2) {
//         margin: 1.75rem;
//         width: 150px;
//         height: 150px;
//     }
    
//     div:nth-child(3) {
//         width: 90px;
//         height: 90px;
//     }
  
//     @media screen and (min-width: 2000px) {
//       div:nth-child(2) {
//         width: 400px;
//         height: 400px;
//       }
  
//       div:nth-child(3) {
//         width: 170px;
//         height: 170px;
//       }
  
//       div:nth-child(1) {
//         width: 200px;
//         height: 200px;
//       }

//     }
  
//     @media screen and (max-width: 1200px) {
//       width: 100%;
//       flex-direction: row;
//       flex-wrap: wrap;
//       margin-left: 0;
  
//       div {
//         margin: 1rem;
//       }
//     }
//   }
  
//   .app__header-img {
//     flex: 1;
//     height: 100%;
//     display: flex;
//     justify-content: flex-end;
//     align-items: flex-end;
//     position: relative;
  
//     img {
//       width: 100%;
//       object-fit: contain;
//       z-index: 1;
//     }
  
//     .overlay_circle {
//       position: absolute;
//       left: 0;
//       right: 0;
//       bottom: 0;
//       z-index: 0;
//       width: 100%;
//       height: 90%;
//     }
  
//     @media screen and (max-width: 1200px) {
//       margin: 2rem 0;
//     }
//   }