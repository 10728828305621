.contact-all{
  position: relative;
  padding-top: 115px;
}
.contact{
  background-color: #FFF;
  min-height: 70vh;
  padding: 150px;
  display: flex;

  h1{
      font-family: 'Raleway', sans-serif;
      text-transform: uppercase;
      padding-bottom: 40px;
  }
  h3{
      font-family: 'Maven Pro', sans-serif;
      padding: 10px 0;
      font-size: large;
  }

  @media screen and (max-width: 1000px) {
      display: block;
      padding: 70px 50px;
    }
}

.contact-1{
  flex: 50%;
}

.contact-2{
  flex: 50%;
  
  .contact-form .form-field {
      position: relative;
      margin: 32px 0;
    }
    .contact-form .input-text {
      display: block;
      width: 100%;
      height: 42px;
      border-width: 0 0 2px 0;
      border-color: #000;
      font-size: small;
      line-height: 20px;
      font-weight: 200;
      font-family: 'Maven Pro', sans-serif;
      background-color: transparent;;
    }
    .contact-form .input-text:focus {
      outline: none;
    }
    .contact-form .input-text:focus + .label,
    .contact-form .input-text.not-empty + .label {
      -webkit-transform: translateY(-24px);
              transform: translateY(-24px);
    }
    .contact-form .label {
      position: absolute;
      left: 20px;
      bottom: 11px;
      font-size: 16px;
      line-height: 26px;
      font-weight: 200;
      color: #000;
      cursor: text;
      transition: -webkit-transform .2s ease-in-out;
      transition: transform .2s ease-in-out;
      transition: transform .2s ease-in-out, 
      -webkit-transform .2s ease-in-out;
    }
    .contact-form .submit-btn {
      display: inline-block;
      background-color: #000;
       background-image: linear-gradient(125deg,#a72879,#064497);
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 16px;
      padding: 8px 16px;
      border: none;
      width:200px;
      cursor: pointer;
    }

    
    
}