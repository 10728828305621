.services{
    position: relative;
    padding-top: 115px;
}

.services-header{
  position: relative;
    img{
        height: 30vh;
        width: 100%;
    }
    h1{
        text-align: center;
        font-family: 'Raleway', sans-serif;
        color: black;
    }
}

.services-header_text{
    text-transform: capitalize;
    width: 100%;
    height: 30vh;
    position: absolute;
    padding: 80px 0;
    top: 0%;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.services-container{
    min-height: 130vh;
    background-color: #F0F8FF;
    padding: 70px 0;

    .services-header{
        padding: 80px 0 60px;
    }
    h1{
        text-align: center;
        font-family: 'Raleway', sans-serif;
        color: black;
    }
    h4{
        font-family: 'Maven Pro', sans-serif;
        color: #000;
        font-weight: 800;
    }
    p{
        font-family: 'Maven Pro', sans-serif;
    }
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  
    .services-container_item {
      max-width: 350px;
      flex-direction: column;
      min-height: 450px;
      margin: 1rem;
      border-radius: 0.5rem;
      background-color: #fff;
      color: #000;
  
      cursor: pointer;
      transition: all 0.3s ease;
  
      &:hover {
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
      }
  
      @media screen and (min-width: 2000px) {
        width: 470px;
        padding: 1.25rem;
        border-radius: 0.75rem;
      }
  
      @media screen and (max-width: 300px) {
        width: 100%;
        margin: 1rem;
      }
    }
  }
  
  .services-container_img {
    width: 100%;
    height: 230px;
  
    position: relative;
  
    img {
      width: 100%;
      height: 100%;
      // border-radius: 0.5rem;
      object-fit: cover;
    }
    
  
    @media screen and (min-width: 2000px) {
      height: 350px;
    }
  }
  
  .services-container_hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  
    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;
  
    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
  
      margin: 1rem;
      font-family: var(--font-base);
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease;
  
      svg {
        width: 50%;
        height: 50%;
        color: var(--white-color);
      }
    }
  }
  
  .services-container_content {
    padding: 1.5rem 1.5rem 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
  
    h4 {
      margin-top: 1rem;
      line-height: 1.5;
  
      @media screen and (min-width: 2000px) {
        margin-top: 3rem;
      }
    }
  
    .services-container_tag {
      position: absolute;
  
      padding: 0.5rem 1rem;
      border-radius: 10px;
      background-color: #fff;
      top: -25px;
    }
  }

  .services-second{
    background-color: #fff;
    min-height: 70vh;

    h1{
        text-align: center;
        font-family: 'Raleway', sans-serif;
        color: #323232;
        text-transform: capitalize;
        padding-top: 60px;
    }
  }

  .services-second_container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.services-second_content{
    max-width: 270px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
    font-family: 'Maven Pro', sans-serif;

    img{
        max-width: 140px;
        padding: 10px 0 10px;
    }
}